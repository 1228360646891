<template>
  <b-card no-body>
    <b-card-header class="p-0 justify-content-center bg-warning align-items-center">
      <h4 class="text-white pt-50">
        {{ $t('company_detail.company_information') }}
      </h4>
    </b-card-header>
    <!-- form -->
    <validation-observer
      ref="loginForm"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="btn_update_return_url_data"
      >
        <b-row class="mx-1">
          <b-col>
            <b-form-group>
              <label for="country"><span class="text-danger text-bold">*</span> {{ $t('company_detail.country') }}</label>
              <b-form-input
                id="country"
                v-model="country"
                disabled
                :placeholder="$t('company_detail.country')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mx-1">
          <b-col>
            <b-form-group>
              <label for="company_name"><span class="text-danger text-bold">*</span> {{ $t('company_detail.company_name') }}</label>
              <validation-provider
                #default="{ errors }"
                name="company_name"
                vid="company_name"
                rules="required"
              >
                <b-form-input
                  id="company_name"
                  v-model="company_name"
                  :state="errors.length > 0 ? false: company_name != null ? null : null"
                  :placeholder="$t('company_detail.company_name')"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >{{ $t('core.required') }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mx-1">
          <b-col>
            <b-form-group>
              <label for="tax_no"><span class="text-danger text-bold">*</span> {{ $t('company_detail.tax_no') }}</label>
              <validation-provider
                #default="{ errors }"
                name="tax_no"
                vid="tax_no"
                rules="required"
              >
                <b-form-input
                  id="tax_no"
                  v-model="tax_no"
                  :state="errors.length > 0 ? false: tax_no != null ? null : null"
                  :placeholder="$t('company_detail.tax_no')"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >{{ $t('core.required') }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mx-1">
          <b-col>
            <b-form-group>
              <label for="postal_code"><span class="text-danger text-bold">*</span> {{ $t('company_detail.postal_code') }}</label>
              <validation-provider
                #default="{ errors }"
                name="postal_code"
                vid="postal_code"
                rules="required"
              >
                <b-form-input
                  id="postal_code"
                  v-model="postal_code"
                  :state="errors.length > 0 ? false: postal_code != null ? null : null"
                  :placeholder="$t('company_detail.postal_code')"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >{{ $t('core.required') }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mx-1">
          <b-col>
            <b-form-group>
              <label for="city"><span class="text-danger text-bold">*</span> {{ $t('company_detail.city') }}</label>
              <validation-provider
                #default="{ errors }"
                name="city"
                vid="city"
                rules="required"
              >
                <b-form-input
                  id="city"
                  v-model="city"
                  :state="errors.length > 0 ? false: city != null ? null : null"
                  :placeholder="$t('company_detail.city')"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >{{ $t('core.required') }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mx-1">
          <b-col>
            <b-form-group>
              <label for="phone_no"><span class="text-danger text-bold">*</span> {{ $t('company_detail.phone_no') }}</label>
              <validation-provider
                #default="{ errors }"
                name="phone_no"
                vid="phone_no"
                rules="required"
              >
                <b-form-input
                  id="phone_no"
                  v-model="phone_no"
                  :state="errors.length > 0 ? false: phone_no != null ? null : null"
                  placeholder="+905433343322"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >{{ $t('core.required') }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-end mx-1 mb-1">
          <b-button
            size="sm"
            variant="primary"
            class="mr-1"
            type="sumbit"
            :disabled="invalid"
          >
            <span class="align-middle">{{ $t('core.btn_update') }}</span>
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import {
  BButton,
  BCard, BCardHeader,
  BCol, BForm, BFormGroup, BFormInput, BRow,
  VBModal,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    BForm,
    BCardHeader,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
  },
  data() {
    return {
      company_name: null,
      tax_no: null,
      postal_code: null,
      country: null,
      city: null,
      phone_no: null,
    }
  },
  watch: {
  },
  created() {
    this.user_data = getUserData()
    this.get_return_url_data()
  },
  mounted() {},
  methods: {
    get_return_url_data() {
      useJwt.companyprofileDetail(this.user_data.company_id)
        .then(response => {
          this.company_name = response.data.company_name
          this.tax_no = response.data.tax_no
          this.postal_code = response.data.postal_code
          this.country = response.data.register_country.native
          this.city = response.data.city
          this.phone_no = response.data.phone
        })
        .catch(error => {
          console.log(error)
        })
    },
    btn_update_return_url_data() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('company_name', this.company_name)
          formData.append('tax_no', this.tax_no)
          formData.append('postal_code', this.postal_code)
          formData.append('city', this.city)
          formData.append('phone', this.phone_no)
          useJwt.updatecompanyprofile(this.user_data.company_id, formData)
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.record_updated'),
                  variant: 'info',
                },
              })
            })
            .catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  variant: 'danger',
                  text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
                },
              })
            })
        }
      })
    },
  },
}
</script>
<style>

</style>
