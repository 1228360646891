<template>
  <b-card no-body>
    <b-card-header class="p-0 justify-content-center bg-warning align-items-center">
      <h4 class="text-white pt-50">
        Genel Tanımlar
      </h4>
    </b-card-header>
    <b-row class="mx-1 mt-1">
      <b-col>
        <b-form-group
            label="Anket tamamlandiktan sonra yönlendirilecek adresi aşağıdaki alana girin."
            label-for="basicInput"
        >
          <b-form-input
              id="basicInput"
              v-model="return_url"
              placeholder="https://geribildir.io"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="justify-content-end mx-1 mb-1">
      <b-button
          @click="btn_update_return_url_data"
          size="sm"
          variant="primary"
          class="mr-1"
      >
        <span class="align-middle">{{ $t('core.btn_update') }}</span>
      </b-button>
    </b-row>
  </b-card>
</template>
<script>
import {
  BButton,
  BCard, BCardHeader,
  BCol, BFormGroup, BFormInput, BRow,
  VBModal,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardHeader,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
  },
  data() {
    return {
      user_data: null,
      return_url: 'https://geribildir.io',
    }
  },
  watch: {
  },
  created() {
    this.user_data = getUserData()
    this.get_return_url_data()
  },
  mounted() {},
  methods: {
    get_return_url_data() {
      useJwt.companyreturnurlDetail(this.user_data.company_id)
        .then(response => {
          this.return_url = response.data.survey_answer_complited_return_url
        })
        .catch(error => {
          console.log(error)
        })
    },
    btn_update_return_url_data() {
      const formData = new FormData()
      formData.append('survey_answer_complited_return_url', this.return_url)
      useJwt.updateCompanyreturnurl(this.user_data.company_id, formData)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.record_updated'),
              variant: 'info',
            },
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
<style>

</style>
