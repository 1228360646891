<template>
  <div>
    <b-modal
      id="modal-1"
      centered
      size="lg"
      :ok-disabled="true"
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <div v-if="payment_modal_tab==='iframe'">
        <b-alert
          v-if="iframe.src===null"
          variant="success"
          show
        >
          <h4 class="alert-heading">
            {{ $t('creditcardpay.you_are_directed_to_the_bank_verification_page') }}
          </h4>
          <h4 class="alert-body">
            {{ $t('creditcardpay.wait_plase') }}
          </h4>
        </b-alert>
        <iframe
          v-if="iframe.src"
          v-show="iframe.loaded"
          ref="frame"
          :srcdoc="iframe.src"
          width="100%"
          height="600px"
          @load="load"
        />
      </div>
      <div v-if="payment_modal_tab==='error'">
        <div class="row justify-content-end mb-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="sm"
            type="submit"
            @click="closePaymentModal"
          >
            X
          </b-button>
        </div>
        <b-alert
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            {{ $t('creditcardpay.payment_failed') }}
          </h4>
          <h4 class="alert-body">
            {{ payment_error_message }}
          </h4>
        </b-alert>
      </div>
      <div v-if="payment_modal_tab==='success'">
        <b-alert
          variant="success"
          show
        >
          <h4 class="alert-heading">
            {{ $t('creditcardpay.verification_successful') }}
          </h4>
          <h4 class="alert-body">
            {{ $t('creditcardpay.please_wait_until_payment_is_received') }}
          </h4>
        </b-alert>
      </div>
    </b-modal>
    <b-card no-body>
      <b-card-header class="p-0 justify-content-center bg-warning align-items-center">
        <h4 class="text-white pt-50">
          {{ $t('payment_method.payment_method') }}
        </h4>
      </b-card-header>
      <b-row class="mx-1 mt-2">
        <span>{{ $t('payment_method.your_current_payment_option_is') }}: </span>
        <span
          v-if="is_set_card"
          class="text-success text-bold"
        > <u>{{ $t('payment_method.credit_card') }}</u></span>
      </b-row>
      <hr>
      <!-- form -->
      <validation-observer
        ref="cardSaveForm"
        #default="{invalid}"
      >
        <b-form
          class="auth-login-form"
          @submit.prevent="btn_company_saved_card"
        >
          <!-- cardno -->
          <b-row class="justify-content-center">
            <b-col md="10">
              <b-form-group>
                <label for="card_no"><span class="text-danger text-bold">*</span> {{ $t('creditcardpay.card_no') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="card_no"
                  rules="required"
                >
                  <cleave
                    id="card_no"
                    v-model="card_no"
                    class="form-control"
                    :raw="false"
                    :options="options.creditCard"
                    :placeholder="is_set_card==true ? saved_card_info.last_4: $t('creditcardpay.card_no')"
                    :state="errors.length > 0 ? false: card_no != null ? true : null"
                  />
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >{{ $t('core.required') }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- cardno -->

          <!-- exp_date and cvv -->
          <b-row class="justify-content-center">
            <b-col md="5">
              <b-form-group>
                <label for="exp_date"><span class="text-danger text-bold">*</span> {{ $t('creditcardpay.exp_date') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="exp_date"
                  rules="required"
                >
                  <cleave
                    id="exp_date"
                    v-model="exp_date"
                    class="form-control"
                    :state="errors.length > 0 ? false: exp_date != null ? true : null"
                    :raw="false"
                    :options="options.exp_date"
                    :placeholder="is_set_card==true ? saved_card_info.date: $t('creditcardpay.exp_date_placeholder')"
                  />
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >{{ $t('core.required') }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group>
                <label for="cvv"><span class="text-danger text-bold">*</span>CVV:</label>
                <validation-provider
                  #default="{ errors }"
                  name="cvv"
                  rules="required"
                >
                  <cleave
                    id="cvv"
                    v-model="cvv"
                    class="form-control"
                    :state="errors.length > 0 ? false: cvv != null ? true : null"
                    :raw="false"
                    :options="options.cvv"
                    :placeholder="is_set_card==true ? '***': 'CVV'"
                  />
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >{{ $t('core.required') }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- exp_date and cvv -->

          <!-- full_name -->
          <b-row class="justify-content-center">
            <b-col md="10">
              <b-form-group>
                <label for="card_holder"><span class="text-danger text-bold">*</span> {{ $t('creditcardpay.card_holder') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="card_holder"
                  rules="required"
                >
                  <b-form-input
                    id="card_holder"
                    v-model="card_holder"
                    :state="errors.length > 0 ? false: card_holder != null ? true : null"
                    name="card_holder"
                    :placeholder="is_set_card==true ? saved_card_info.c_name: $t('creditcardpay.card_holder')"
                  />
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >{{ $t('core.required') }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- full_name -->
          <!-- checkbox -->
          <b-row class="  justify-content-center pt-1 mb-1 bg-light-warning mx-auto">
            <b-col md="10">
              <validation-provider
                #default="{ errors }"
                name="checkbox"
                rules="required"
              >
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="chekcbox_status"
                  :value="true"
                  :unchecked-value="null"
                  switch
                  :state="chekcbox_status != null ? true : null"
                  name="checkbox-1"
                >
                  'geribildir.io', {{ $t('payment_method.creditcardpay_accept') }}
                </b-form-checkbox>
                <small
                  v-if="chekcbox_status == false"
                  class="text-danger"
                >{{ $t('core.required') }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- checkbox -->
          <b-row class="justify-content-end mx-1 mb-1">
            <b-button
              size="sm"
              variant="primary"
              class="mr-1"
              type="sumbit"
              :disabled="invalid"
            >
              <span class="align-middle">{{ $t('core.btn_update') }}</span>
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import {
  BAlert,
  BButton,
  BCard, BCardHeader,
  BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BRow,
  VBModal,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BAlert,
    BFormCheckbox,
    BForm,
    BCardHeader,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
  },
  data() {
    return {
      form: {
        cardNumber: null,
        date: null,
        phone: null,
      },
      options: {
        creditCard: {
          delimiter: '-',
          blocks: [4, 4, 4, 4],
        },
        cvv: {
          blocks: [3],
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        exp_date: {
          date: true,
          delimiter: '/',
          datePattern: ['m', 'y'],
          blocks: [2, 2],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+63',
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      },
      is_set_card: false,
      saved_card_info: {},
      card_no: null,
      exp_date: null,
      cvv: null,
      card_holder: null,
      chekcbox_status: null,
      required,
      temp_order_no: null,
      iframe: {
        src: null,
        loaded: false,
      },
      payment_modal_tab: 'iframe',
    }
  },
  watch: {
  },
  created() {
    this.user_data = getUserData()
    this.get_payment_data()
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
  },
  methods: {
    receiveMessage(event) {
      if (event.data.func === 'payment_log') {
        if (event.data.message.payment_status === 'True') {
          this.payment_modal_tab = 'success'
          this.PaymentCheck()
        } else {
          this.payment_modal_tab = 'error'
          this.payment_error_message = event.data.message.message
        }
      }
    },
    load() { this.iframe.loaded = true },
    PaymentCheck() {
      const payload = {
        order_no: this.temp_order_no,
      }
      useJwt.paymentcheck(payload)
        .then(response => {
          if (response.data.order_status === 'processing') {
            setTimeout(this.PaymentCheck, 3000) // 3 saniye sonra tekrar çalışır
          } else if (response.data.order_status === 'completed') {
            this.closePaymentModal()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('core.record_updated'),
                icon: 'SaveIcon',
                variant: 'success',
              },
            })
          } else if (response.data.order_status === 'canceled') {
            console.log('kart eklenemedi hatası  verilecek')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('core.error'),
                icon: 'SaveIcon',
                variant: 'danger',
              },
            })
            this.payment_modal_tab = 'error'
            this.payment_error_message = `${response.data.failed_reason_code} - ${response.data.failed_reason_msg}`
          }
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    get_payment_data() {
      useJwt.companyPaymentDetail(this.user_data.company_id)
        .then(response => {
          this.is_set_card = response.data.status
          this.saved_card_info = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    btn_company_saved_card() {
      this.$refs.cardSaveForm.validate().then(success => {
        if (success) {
          const payload = {
            card_no: this.card_no.replaceAll('-', ''),
            exp_date: this.exp_date,
            cvv: this.cvv,
            card_holder: this.card_holder,
          }
          useJwt.updateCompanySavedCard(payload)
            .then(response => {
              if (response.data.status === true) {
                this.$bvModal.show('modal-1')
                this.iframe.src = response.data.threeDSHtmlContent
                this.temp_order_no = response.data.order_no
              } else {
                this.$bvModal.hide('modal-1')
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('core.error'),
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  variant: 'danger',
                  text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
                },
              })
            })
        }
      })
    },
    closePaymentModal() {
      this.$bvModal.hide('modal-1')
      this.iframe = { src: null, loaded: false }
      this.payment_modal_tab = 'iframe'
    },
  },
}
</script>
<style>

</style>
