<template>
  <div>
    <!--    3ds Modal Modal-->
    <b-modal
      id="3dsmodal"
      centered
      size="lg"
      :ok-disabled="true"
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <div v-if="payment_modal_tab==='iframe'">
        <b-alert
          v-if="iframe.src===null"
          variant="success"
          show
        >
          <h4 class="alert-heading">
            {{ $t('creditcardpay.you_are_directed_to_the_bank_verification_page') }}
          </h4>
          <h4 class="alert-body">
            {{ $t('creditcardpay.wait_plase') }}
          </h4>
        </b-alert>
        <iframe
          v-if="iframe.src"
          v-show="iframe.loaded"
          ref="frame"
          :srcdoc="iframe.src"
          width="100%"
          height="600px"
          @load="load"
        />
      </div>
      <div v-if="payment_modal_tab==='error'">
        <div class="row justify-content-end mb-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="sm"
            type="submit"
            @click="closePaymentModal"
          >
            X
          </b-button>
        </div>
        <b-alert
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            {{ $t('creditcardpay.payment_failed') }}
          </h4>
          <h4 class="alert-body">
            {{ payment_error_message }}
          </h4>
        </b-alert>
      </div>
      <div v-if="payment_modal_tab==='success'">
        <b-alert
          variant="success"
          show
        >
          <h4 class="alert-heading">
            {{ $t('creditcardpay.verification_successful') }}
          </h4>
          <h4 class="alert-body">
            {{ $t('creditcardpay.please_wait_until_payment_is_received') }}
          </h4>
        </b-alert>
      </div>
    </b-modal>
    <!--    3ds Modal Modal-->
    <!--    Abonelik İptal Modal-->
    <b-modal
      id="subscription_stop_modal"
      ref="subscription_stop_modal"
      centered
      no-close-on-backdrop
      content-class="shadow"
      :title="$t('plan_management.subscription_stop')"
      footer-class="d-none"
      size="md"
      @hide="close_subscription_stop_modal"
    >
      <div>
        <b-row class="justify-content-center">
          <h4>Aboneliği sonlandırmak istediğinize emin misiniz?</h4>
        </b-row>
        <b-row class="mx-25 mt-50">
          <p>Aboneliği sonlandırmanız durumunda xx/xx/xx tarihine kadar sistemi kullanabilirsiniz.</p>
          <p>xx/xx/xx tarihinden sonra sisteme erişiminiz durdurulacak ve verileriniz silinecektir.</p>
          <p>xx/xx/xx tarihinden önce abonelik sonlandırma işlemini iptal edebilirsiniz.</p>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
            size="sm"
            variant="secondary"
            class="mr-1"
            @click="close_subscription_stop_modal"
          >
            <span class="align-middle">{{ $t('core.btn_cancel') }}</span>
          </b-button>
          <b-button
            size="sm"
            variant="danger"
            class="mr-1"
            @click="btn_subscription_stop(false)"
          >
            <span class="align-middle">{{ $t('plan_management.subscription_stop') }}</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Abonelik İptal Modal-->

    <!--    Abonelik Yükselt Modal-->
    <b-modal
      id="subscription_upgrade_modal"
      ref="subscription_upgrade_modal"
      centered
      no-close-on-backdrop
      content-class="shadow"
      :title="$t('plan_management.subscription_upgrade')"
      footer-class="d-none"
      size="lg"
      @hide="close_subscription_upgrade_modal"
    >
      <!-- form -->
      <validation-observer
        ref="upgradeForm"
        #default="{invalid}"
      >
        <b-form @submit.prevent="subscriptionUpgrade">
          <div>
            <b-alert
              class="w-100"
              variant="info"
              show
            >
              <div class="alert-body">
                - Kalan günler için eski plan ücretiniz düşülecek ve yeni plan ücretiniz hesaplanacaktır.<br>
                - Aşağıda ödeyeceğiniz tutarı görebilirsiniz.
              </div>
            </b-alert>
            <b-row class="justify-content-around">
              <b-col
                v-if="subscription_upgrade_amount_calculation_info_data.current_plan.plan_name!== null"
                cols="5"
                class="text-center border border-success p-0"
              >
                <u class="text-success"><h5 class="text-success">Mevcut Planınız</h5></u>
                <span
                  style="font-size: 12px"
                  class="d-flex ml-25"
                ><b>- Plan Adı</b>: {{ subscription_upgrade_amount_calculation_info_data.current_plan.plan_name }} / {{ get_current_period_name(subscription_upgrade_amount_calculation_info_data.current_plan.current_period) }}</span>
                <span
                  style="font-size: 12px"
                  class="d-flex ml-25"
                ><b>- Plan Fiyatı</b>: {{ info_price_formetter(subscription_upgrade_amount_calculation_info_data.current_plan.current_plan_price) }}</span>
                <span
                  style="font-size: 12px"
                  class="d-flex ml-25"
                ><b>- Kalan Süre</b>: {{ subscription_upgrade_amount_calculation_info_data.current_plan.remaining_days }} gün</span>
                <span
                  style="font-size: 12px"
                  class="d-flex ml-25 align-items-center"
                ><b>- Düşülecek Fiyat</b>: {{ info_price_formetter(subscription_upgrade_amount_calculation_info_data.current_plan.remaining_amount) }} <span style="font-size: 10px">( {{ info_price_formetter(subscription_upgrade_amount_calculation_info_data.current_plan.current_plan_price) }} / {{ subscription_upgrade_amount_calculation_info_data.current_plan.total_day }} gün x {{ subscription_upgrade_amount_calculation_info_data.current_plan.remaining_days }} gün )</span></span><br>
              </b-col>
              <b-col
                cols="5"
                class="text-center border border-primary p-0"
              >
                <u class="text-primary"><h5 class="text-primary">Yeni Planınız</h5></u>
                <span
                  style="font-size: 12px"
                  class="d-flex ml-25"
                ><b>- Plan Adı</b>: {{ subscription_upgrade_amount_calculation_info_data.new_plan_name }} / {{ get_current_period_name(status) }}</span>
                <span
                  v-if="subscription_upgrade_amount_calculation_info_data.current_plan.plan_name!== null"
                  style="font-size: 12px"
                  class="d-flex ml-25"
                ><b>- Planın Fiyatı</b>: {{ info_price_formetter(subscription_upgrade_amount_calculation_info_data.calculated_newplan_price) }} <span
                  v-if="subscription_upgrade_amount_calculation_info_data.current_plan.plan_name!== null"
                  style="font-size: 10px"
                >( {{ info_price_formetter(subscription_upgrade_amount_calculation_info_data.new_plan_price) }} / {{ subscription_upgrade_amount_calculation_info_data.current_plan.total_day }} gün x {{ subscription_upgrade_amount_calculation_info_data.current_plan.remaining_days }} gün )</span></span>
                <span
                  v-if="subscription_upgrade_amount_calculation_info_data.current_plan.plan_name!== null"
                  style="font-size: 12px"
                  class="d-flex ml-25 align-items-center"
                ><b>- Düşülecek Fiyat</b>: {{ info_price_formetter(subscription_upgrade_amount_calculation_info_data.current_plan.remaining_amount) }} <span style="font-size: 10px">( {{ info_price_formetter(subscription_upgrade_amount_calculation_info_data.current_plan.current_plan_price) }} / {{ subscription_upgrade_amount_calculation_info_data.current_plan.total_day }} gün x {{ subscription_upgrade_amount_calculation_info_data.current_plan.remaining_days }} gün )</span></span>
                <span
                  style="font-size: 12px"
                  class="d-flex ml-25"
                ><b>- Ödenecek Tutar</b>: {{ info_price_formetter(subscription_upgrade_amount_calculation_info_data.amount_pay) }}</span>
              </b-col>
            </b-row>
            <hr>
            <b-row class="justify-content-center mt-1">
              <b-col cols="6">
                <label for="card_no"><span class="text-danger text-bold">*</span> Ödeme Yöntemi:</label>
                <v-select
                  id="i-plan"
                  v-model="paymnet_method"
                  :options="paymnet_method_options"
                  label="title"
                  :clearable="false"
                  class="mx-25"
                >
                  <template #option="{ title, icon }" />
                </v-select>
              </b-col>
            </b-row>
            <b-row
              v-if="paymnet_method.value=='new_card'"
              class="justify-content-center mt-1 "
            >
              <b-col cols="6">
                <!-- no show if amount 0 -->
                <div>
                  <!-- cardno -->
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <label for="card_no"><span class="text-danger text-bold">*</span> {{ $t('creditcardpay.card_no') }}</label>
                        <validation-provider
                          #default="{ errors }"
                          name="card_no"
                          rules="required"
                        >
                          <cleave
                            id="card_no"
                            v-model="card_no"
                            class="form-control"
                            :raw="false"
                            :options="options.creditCard"
                            :placeholder="$t('creditcardpay.card_no')"
                            :state="errors.length > 0 ? false: card_no != null ? true : null"
                          />
                          <small
                            v-if="errors[0]"
                            class="text-danger"
                          >{{ $t('core.required') }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- cardno -->

                  <!-- exp_date and cvv -->
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <label for="exp_date"><span class="text-danger text-bold">*</span> {{ $t('creditcardpay.exp_date') }}</label>
                        <validation-provider
                          #default="{ errors }"
                          name="exp_date"
                          rules="required"
                        >
                          <cleave
                            id="exp_date"
                            v-model="exp_date"
                            class="form-control"
                            :state="errors.length > 0 ? false: exp_date != null ? true : null"
                            :raw="false"
                            :options="options.exp_date"
                            :placeholder="$t('creditcardpay.exp_date_placeholder')"
                          />
                          <small
                            v-if="errors[0]"
                            class="text-danger"
                          >{{ $t('core.required') }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <label for="cvv"><span class="text-danger text-bold">*</span>CVV:</label>
                        <validation-provider
                          #default="{ errors }"
                          name="cvv"
                          rules="required"
                        >
                          <cleave
                            id="cvv"
                            v-model="cvv"
                            class="form-control"
                            :state="errors.length > 0 ? false: cvv != null ? true : null"
                            :raw="false"
                            :options="options.cvv"
                            placeholder="CVV"
                          />
                          <small
                            v-if="errors[0]"
                            class="text-danger"
                          >{{ $t('core.required') }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- exp_date and cvv -->

                  <!-- full_name -->
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <label for="card_holder"><span class="text-danger text-bold">*</span> {{ $t('creditcardpay.card_holder') }}</label>
                        <validation-provider
                          #default="{ errors }"
                          name="card_holder"
                          rules="required"
                        >
                          <b-form-input
                            id="card_holder"
                            v-model="card_holder"
                            :state="errors.length > 0 ? false: card_holder != null ? true : null"
                            name="card_holder"
                            :placeholder="$t('creditcardpay.card_holder')"
                          />
                          <small
                            v-if="errors[0]"
                            class="text-danger"
                          >{{ $t('core.required') }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- full_name -->
                </div>
                <!-- no show if amount 0 -->
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <!-- lisance -->
                <b-row>
                  <b-col>
                    <span style="font-size: 10px">{{ $t('creditcardpay.warning_1') }}</span><br>
                    <span style="font-size: 10px">{{ $t('creditcardpay.warning_2') }}</span><br>
                    <span style="font-size: 10px">{{ $t('creditcardpay.warning_3') }}</span><br>
                    <span style="font-size: 10px"><b>geribildir.io</b>, {{ $t('creditcardpay.warning_4') }}</span><br>
                  </b-col>
                </b-row>
                <!-- lisance -->

                <!-- checkbox -->
                <b-row>
                  <b-col>
                    <validation-provider
                      #default="{ errors }"
                      name="checkbox"
                      rules="required"
                    >
                      <b-form-checkbox
                        id="register-privacy-policy"
                        v-model="chekcbox_status"
                        :value="true"
                        :unchecked-value="null"
                        switch
                        :state="chekcbox_status != null ? true : null"
                        name="checkbox-1"
                      >
                        {{ $t('creditcardpay.accept') }}
                      </b-form-checkbox>
                      <small
                        v-if="chekcbox_status == false"
                        class="text-danger"
                      >{{ $t('core.required') }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <!-- checkbox -->
              </b-col>
            </b-row>
            <hr>
            <b-row class="justify-content-end ">
              <b-button
                size="sm"
                variant="secondary"
                class="mr-1"
                @click="close_subscription_upgrade_modal"
              >
                <span class="align-middle">{{ $t('core.btn_cancel') }}</span>
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                class="mr-1"
                type="submit"
                :disabled="invalid"
              >
                <span class="align-middle">{{ $t('plan_management.subscription_upgrade') }}</span>
              </b-button>
            </b-row>
          </div>
        </b-form>
      </validation-observer>

    </b-modal>
    <!--    Abonelik Yükselt Modal-->

    <b-card no-body>
      <b-card-header class="p-0 bg-warning w-100">
        <div class="row w-100 p-0 m-0">
          <b-col />
          <b-col class="justify-content-center text-center">
            <h4 class="text-white pt-50">
              {{ $t('plan_management.plan_management') }}
            </h4>
          </b-col>
          <b-col class="align-content-end justify-content-end mr-0 pr-0">
            <div
              v-if="company_data.plan.plan_tag!='free'&&subscription_data.subscription_renewal==true"
              class="d-flex mr-0 justify-content-end"
            >
              <b-button
                v-b-modal.subscription_stop_modal
                size="sm"
                class="m-25"
                variant="danger"
              >
                {{ $t('plan_management.subscription_stop') }}
              </b-button>
            </div>

          </b-col>
        </div>
      </b-card-header>

      <!-- abonelik iptal -->
      <b-row
        v-if="company_data.plan.plan_tag!='free'&&subscription_data.subscription_renewal==false"
        class="mx-1 mt-50 text-danger "
      >
        <b-alert
          class="w-100"
          variant="danger"
          show
        >
          <div class="alert-heading">
            Aboneliğiniz {{ subscription_data.end_date }} tarihinde sonlandırılacaktır.
          </div>
          <div class="alert-body">
            Sonlandırma işlemini geri almak için <u @click="btn_subscription_stop(true)">buraya tıklayın.</u>
          </div>
        </b-alert>
      </b-row>
      <!-- abonelik iptal -->
      <!-- Abonelik fiyat düşeceğine dair bilgi ekranı     -->
      <b-row
        v-if="company_data.plan.plan_tag!='free'&&subscription_data.subscription_renewal==true"
        class="mx-1 mt-50"
      >
        <b-alert
          class="w-100"
          variant="info"
          show
        >
          <div class="alert-body">
            - Kalan günler için eski plan ücretiniz düşülecek ve yeni plan ücretiniz hesaplanacaktır.<br>
          </div>
        </b-alert>
      </b-row>
      <!-- Abonelik fiyat düşeceğine dair bilgi ekranı     -->

      <!-- plan periyot toogle     -->
      <div
        v-if="company_data.plan_period!='annually'|company_data.plan.plan_tag=='free'"
        class=" mt-50 d-flex align-items-center justify-content-center"
      >
        <h6 class="mr-1 mb-0">
          {{ $t('plan_select.monthly') }}
        </h6>
        <b-form-checkbox
          id="priceSwitch"
          v-model="status"
          name="price-switch"
          value="annually"
          unchecked-value="monthly"
          switch
          @input="tooglePlan"
        />
        <h6 class="ml-50 mb-0">
          {{ $t('plan_select.yearly') }}
        </h6>
      </div>
      <!-- plan periyot toogle     -->

      <!-- pricing plan cards -->
      <b-row class="p-1">
        <b-col
          v-for="plan in planList"
          v-if="plan.plan_tag !='free'&&company_data.plan.priority_no<=plan.priority_no"
          :key="plan.locale"
          class="mx-50 rounded border  p-0 pb-50"
        >
          <h4
            class="text-center py-50 rounded border text-white"
            :class="(plan.plan_tag !=='free' && plan.plan_tag !=='enterprise' && company_data.plan.priority_no==plan.priority_no && company_data.plan_period==status) ? 'bg-success': 'bg-primary' "
          >
            {{ plan.title }}
          </h4>
          <!-- annual plan -->
          <div class="text-center">
            <div class="my-25">
              <div v-show="plan.plan_tag !='enterprise'">
                <div class="text-body text-nowrap font-weight-bold">
                  <h3>
                    {{ price_formetter(plan) }}
                  </h3>
                </div>
              </div>
              <div v-show="plan.plan_tag =='enterprise'">
                <div class="text-body text-nowrap font-weight-bold">
                  <h3>-
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <!--/ annual plan -->

<!--          &lt;!&ndash; plan benefit &ndash;&gt;-->
<!--          <span-->
<!--            v-for="(data,index) in plan.features.split('|')"-->
<!--            :key="index"-->
<!--            class="text-left ml-25"-->
<!--            style="font-size: 12px"-->
<!--          >-->
<!--            ✅ {{ data }} <hr>-->
<!--          </span>-->
<!--          &lt;!&ndash;/ plan benefit &ndash;&gt;-->
          <hr>
          <div v-b-tooltip.hover="$t('plan_select.hover_qr_code')" class="text-nowrap d-flex mx-1" style="font-size: 12px">✅ {{$t('plan_select.qr_code')}}: <b class="ml-25"><div v-if="plan.plan_tag !='enterprise'">{{ plan.qr_code_limit }}</div><div v-else>♾️</div></b></div><hr>
          <div v-b-tooltip.hover="$t('plan_select.hover_user')" class="text-nowrap d-flex mx-1" style="font-size: 12px">✅ {{$t('plan_select.user')}}: <b class="ml-25"><div v-if="plan.plan_tag !='enterprise'">{{ plan.total_user_limit }}</div><div v-else>♾️</div></b></div><hr>
          <div v-b-tooltip.hover="$t('plan_select.hover_group')" class="text-nowrap d-flex mx-1" style="font-size: 12px">✅ {{$t('plan_select.group')}}: <b class="ml-25"><div v-if="plan.plan_tag !='enterprise'">{{ plan.total_group_limit }}</div><div v-else>♾️</div></b></div><hr>
          <div v-b-tooltip.hover="$t('plan_select.hover_organization')" class="text-nowrap d-flex mx-1" style="font-size: 12px">✅ {{$t('plan_select.organization')}}: <b class="ml-25"><div v-if="plan.plan_tag !='enterprise'">{{ plan.total_organization_limit }}</div><div v-else>♾️</div></b></div><hr>
          <div v-b-tooltip.hover="$t('plan_select.hover_storage')" class="text-nowrap d-flex mx-1" style="font-size: 12px">✅ {{$t('plan_select.storage')}}: <b class="ml-25"><div v-if="plan.plan_tag !='enterprise'">{{ plan.max_disk_quota / (1024 ** 3) }} GB</div><div v-else>♾️</div></b></div><hr>
          <div v-b-tooltip.hover="$t('plan_select.hover_biolink')" class="text-nowrap d-flex mx-1" style="font-size: 12px"><div v-if="plan.biolink_status">✅</div><div v-else>❌</div>  <div class="ml-25">BioLink</div></div><hr>
          <div v-b-tooltip.hover="$t('plan_select.hover_return')" class="text-nowrap d-flex mx-1" style="font-size: 12px"><div v-if="plan.custom_return_url">✅</div><div v-else>❌</div>  <div class="ml-25">{{$t('plan_select.return')}}</div></div><hr>
          <div v-b-tooltip.hover="$t('plan_select.hover_custom_logo')" class="text-nowrap d-flex mx-1" style="font-size: 12px"><div v-if="plan.custom_logo">✅</div><div v-else>❌</div>  <div class="ml-25">{{$t('plan_select.custom_logo')}}</div></div><hr>
          <div v-b-tooltip.hover="$t('plan_select.hover_on_premise')" class="text-nowrap d-flex mx-1" style="font-size: 12px"><div v-if="plan.on_prime">✅</div><div v-else>❌</div>  <div class="ml-25">{{$t('plan_select.on_premise')}}</div></div><hr>


          <!-- buttons -->
          <div class="text-center">
            <b-button
              v-show="plan.plan_tag ==='enterprise'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              class="text-nowrap"
              variant="outline-primary"
              @click="planSelectButton(plan)"
            >
              {{ $t('plan_select.contact_us') }}
            </b-button>
            <b-button
              v-show="plan.plan_tag !=='free' && plan.plan_tag !=='enterprise' && (company_data.plan.priority_no!=plan.priority_no || company_data.plan_period!=status)"
              size="sm"
              variant="outline-primary"
              @click="subscription_upgrade_modal(plan)"
            >
              {{ $t('plan_management.subscription_upgrade') }}
            </b-button>
            <b-button
              v-show="plan.plan_tag !=='free' && plan.plan_tag !=='enterprise' && company_data.plan.priority_no==plan.priority_no && company_data.plan_period==status"
              size="sm"
              variant="success"
              disabled
            >
              Aktif Plan
            </b-button>
          </div>
        </b-col>
      </b-row>
      <!--/ pricing plan cards -->
    </b-card>
  </div>
</template>
<script>
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend, BLink,
  BListGroup,
  BListGroupItem,
  BModal,
  BRow,
  VBModal,
  VBToggle, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@core/utils/validations/validations'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    ValidationProvider,
    vSelect,
    BAlert,
    BLink,
    BModal,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BCardText,
    BListGroup,
    BListGroupItem,
    BCardHeader,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BFormGroup,
    BFormInput,
    Cleave,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
  },
  data() {
    return {
      iframe: {
        src: null,
        loaded: false,
      },
      payment_modal_tab: 'iframe',
      chekcbox_status: null,
      form: {
        cardNumber: null,
        date: null,
        phone: null,
      },
      options: {
        creditCard: {
          delimiter: '-',
          blocks: [4, 4, 4, 4],
        },
        cvv: {
          blocks: [3],
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        exp_date: {
          date: true,
          delimiter: '/',
          datePattern: ['m', 'y'],
          blocks: [2, 2],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+63',
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      },
      card_no: null,
      exp_date: null,
      cvv: null,
      card_holder: null,
      user_data: null,
      company_data: {
        plan: { plan_tag: 'free' },
        plan_period: 'annually',
      },
      subscription_data: {
        subscription_renewal: null,
      },
      required,
      status: 'annually',
      monthlyPlanShow: false,
      enterpriseContactUrl: process.env.VUE_APP_ENTERPRISE_CONTACT_URL,
      planList: [],
      selected_plan: null,
      paymnet_method: { title: 'Hepsi', value: 'new_card' },
      paymnet_method_options: [
        { title: 'Yeni Kredi/Banka Kartı', value: 'new_card' },
      ],
      subscription_upgrade_amount_calculation_info_data: {
        current_plan: {
          plan_name: null,
          remaining_days: null,
          remaining_amount: null,
          current_plan_price: null,
          amount_pay: null,
          last_day: null,
          total_day: null,
          calculated_newplan_price: null,
        },
        currency: 'TL',
        new_plan_name: null,
        new_plan_price: null,
      },
    }
  },
  watch: {
  },
  created() {
    this.user_data = getUserData()
    useJwt.companyprofileDetail(this.user_data.company_id).then(response => {
      this.company_data = response.data
      if (this.company_data.plan.plan_tag != 'free') { this.get_subscription_data() }
    })
    this.getPlanData()
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
  },
  setup() {
  },
  methods: {
    receiveMessage(event) {
      if (event.data.func === 'payment_log') {
        if (event.data.message.payment_status === 'True') {
          this.payment_modal_tab = 'success'
          this.PaymentCheck()
        } else {
          this.payment_modal_tab = 'error'
          this.payment_error_message = event.data.message.message
        }
      }
    },
    load() { this.iframe.loaded = true },
    PaymentCheck() {
      const payload = {
        order_no: this.temp_order_no,
      }
      useJwt.paymentcheck(payload)
        .then(response => {
          if (response.data.order_status === 'processing') {
            setTimeout(this.PaymentCheck, 3000) // 3 saniye sonra tekrar çalışır
          } else if (response.data.order_status === 'completed') {
            this.closePaymentModal()
            this.close_subscription_upgrade_modal()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('core.record_updated'),
                icon: 'SaveIcon',
                variant: 'success',
              },
            })
          } else if (response.data.order_status === 'canceled') {
            console.log('kart eklenemedi hatası  verilecek')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('core.error'),
                icon: 'SaveIcon',
                variant: 'danger',
              },
            })
            this.payment_modal_tab = 'error'
            this.payment_error_message = `${response.data.failed_reason_code} - ${response.data.failed_reason_msg}`
          }
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    closePaymentModal() {
      this.$bvModal.hide('3dsmodal')
      this.iframe = { src: null, loaded: false }
      this.payment_modal_tab = 'iframe'
    },
    get_current_period_name(period) {
      if (period == 'annually') {
        return 'Yıllık'
      }
      return 'Aylık'
    },
    get_subscription_data() {
      useJwt.getSubscriptionList({})
        .then(response => {
          if (response.data.results.length) {
            this.subscription_data = response.data.results[0]
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    price_formetter(plan) {
      const currency = plan.currency.currency_name
      let formatter = null
      if (currency == 'TL') {
        formatter = new Intl.NumberFormat('tr-TR', {
          style: 'currency', currency: 'TRY', minimumFractionDigits: 0, maximumFractionDigits: 0,
        })
      } else if (currency == 'USD') {
        formatter = new Intl.NumberFormat('en-US', {
          style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0,
        })
      } else if (currency == 'EUR') {
        formatter = new Intl.NumberFormat('de-DE', {
          style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0,
        })
      }
      if (this.monthlyPlanShow) {
        return formatter.format(plan.monthly_price)
      }
      return formatter.format(plan.annual_price)
    },
    info_price_formetter(price) {
      const { currency } = this.subscription_upgrade_amount_calculation_info_data
      let formatter = null
      if (currency == 'TL') {
        formatter = new Intl.NumberFormat('tr-TR', {
          style: 'currency', currency: 'TRY', minimumFractionDigits: 0, maximumFractionDigits: 0,
        })
      } else if (currency == 'USD') {
        formatter = new Intl.NumberFormat('en-US', {
          style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0,
        })
      } else if (currency == 'EUR') {
        formatter = new Intl.NumberFormat('de-DE', {
          style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0,
        })
      }
      if (this.monthlyPlanShow) {
        return formatter.format(price)
      }
      return formatter.format(price)
    },
    planSelectButton(plan_name) {
      useJwt.clientregisterfreeplan({
        plan_period: this.status,
        plan_tag: plan_name.plan_tag,
      })
        .then(response => {
          if (plan_name.plan_tag === 'free') {
            const user_data = response.data.userData
            localStorage.setItem('userData', JSON.stringify(user_data))
            this.$ability.update(response.data.userData.ability)
            const routerPath = { name: 'dashboard' }
            this.$router.replace(routerPath)
          } else if (plan_name.plan_tag === 'enterprise') {
            window.open(this.enterpriseContactUrl, '_blank')
          } else if (plan_name.plan_tag !== 'enterprise') {
            const payload = {}
            payload.plan = plan_name
            payload.planPeriod = this.status
            localStorage.setItem('planData', JSON.stringify(payload))
            this.$router.push({ name: 'auth-register-creditcardpay' })
          }
        })
    },
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
    getPlanData() {
      useJwt.getPlanLocationListforClient({})
        .then(response => {
          this.planList = response.data
        })
        .catch(error => {
          console.log(error.response)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
            },
          })
        })
    },
    subscription_upgrade_modal(plan) {
      this.selected_plan = plan
      const formData = new FormData()
      formData.append('new_plan_id', plan.id)
      formData.append('plan_period', this.status)
      useJwt.subscriptionUpgradeAmountCalculation(formData)
        .then(response => {
          this.subscription_upgrade_amount_calculation_info_data = response.data
          if (response.data.saved_card_status == true) {
            const saved_card = {
              title: response.data.last_4, value: response.data.utoken,
            }
            this.paymnet_method_options.push(saved_card)
            this.paymnet_method = saved_card
          }
          this.$refs.subscription_upgrade_modal.show()
        })
        .catch(error => {
          console.log(error.response)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
            },
          })
        })
    },
    subscriptionUpgrade() {
      this.$refs.upgradeForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('new_plan_id', this.selected_plan.id)
          formData.append('plan_period', this.status)
          formData.append('paymnet_method', this.paymnet_method.value)
          if (this.paymnet_method.value == 'new_card') {
            formData.append('card_no', this.card_no.replaceAll('-', ''))
            formData.append('exp_date', this.exp_date)
            formData.append('cvv', this.cvv)
            formData.append('card_holder', this.card_holder)
          } else {
            formData.append('card_no', '1234123412341234')
            formData.append('exp_date', '12/40')
            formData.append('cvv', '000')
            formData.append('card_holder', 'free free')
          }
          useJwt.subscriptionUpgrade(formData)
            .then(response => {
              if (response.data.status === true) {
                if (response.data.action == 'page_reload') {
                  location.reload()
                } else if (response.data.action == 'payment'){
                  this.$bvModal.show('3dsmodal')
                  // Param pos//
                  this.iframe.src = response.data.threeDSHtmlContent
                  this.temp_order_no = response.data.order_no
                  // Param pos//
                  // iyzicp pos//
                  // const threeDSHtmlContent = atob(response.data.threeDSHtmlContent)
                  // this.iframe.src = `data:text/html;charset=utf-8,${encodeURI(threeDSHtmlContent)}`
                  // iyzicp pos//
                }
              } else {
                this.$bvModal.hide('3dsmodal')
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('core.error'),
                    icon: 'EditIcon',
                    variant: 'danger',
                    text: response.data.error_message,
                  },
                })
              }
            })
            .catch(error => {
              console.log(error.response)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
                },
              })
            })
        }
      })
    },
    btn_subscription_stop(renewal_status) {
      this.subscription_data = null
      const formData = new FormData()
      formData.append('renewal', renewal_status)
      useJwt.SubscriptionRenewal(formData)
        .then(response => {
          // this.planList = response.data
          this.close_subscription_stop_modal()
          this.get_subscription_data()
        })
        .catch(error => {
          console.log(error.response)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
            },
          })
        })
    },
    close_subscription_stop_modal() {
      this.$refs.subscription_stop_modal.hide()
    },
    close_subscription_upgrade_modal() {
      this.$refs.subscription_upgrade_modal.hide()
      this.selected_plan = null
      this.chekcbox_status = null
      this.card_no = null
      this.exp_date = null
      this.cvv = null
      this.card_holder = null
      this.paymnet_method = { title: 'Hepsi', value: 'new_card' }
      this.paymnet_method_options = [{ title: 'Yeni Kredi/Banka Kartı', value: 'new_card' }]
      this.subscription_upgrade_amount_calculation_info_data = {
        current_plan: {
          plan_name: null,
          remaining_days: null,
          remaining_amount: null,
          current_plan_price: null,
          amount_pay: null,
          last_day: null,
          total_day: null,
          calculated_newplan_price: null,
        },
        currency: 'TL',
        new_plan_name: null,
        new_plan_price: null,
      }
    },
  },
}
</script>
<style>

</style>
