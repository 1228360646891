<template>
  <div>
    <!-- 3ds Modal    -->
    <b-modal
      id="modal-1"
      centered
      size="lg"
      :ok-disabled="true"
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <div v-if="payment_modal_tab==='iframe'">
        <b-alert
          v-if="iframe.src===null"
          variant="success"
          show
        >
          <h4 class="alert-heading">
            {{ $t('creditcardpay.you_are_directed_to_the_bank_verification_page') }}
          </h4>
          <h4 class="alert-body">
            {{ $t('creditcardpay.wait_plase') }}
          </h4>
        </b-alert>
        <iframe
          v-if="iframe.src"
          v-show="iframe.loaded"
          ref="frame"
          :srcdoc="iframe.src"
          width="100%"
          height="600px"
          @load="load"
        />
      </div>
      <div v-if="payment_modal_tab==='error'">
        <div class="row justify-content-end mb-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="sm"
            type="submit"
            @click="closePaymentModal"
          >
            X
          </b-button>
        </div>
        <b-alert
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            {{ $t('creditcardpay.payment_failed') }}
          </h4>
          <h4 class="alert-body">
            {{ payment_error_message }}
          </h4>
        </b-alert>
      </div>
      <div v-if="payment_modal_tab==='success'">
        <b-alert
          variant="success"
          show
        >
          <h4 class="alert-heading">
            {{ $t('creditcardpay.verification_successful') }}
          </h4>
          <h4 class="alert-body">
            {{ $t('creditcardpay.please_wait_until_payment_is_received') }}
          </h4>
        </b-alert>
      </div>
    </b-modal>
    <!-- 3ds Modal    -->

    <!--    Kredi Kartı Modal-->
    <b-modal
      id="credit_card_modal"
      ref="credit_card_modal"
      class="danger"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Fatura Öde"
      footer-class="d-none"
      size="lg"
      @hide="close_credit_card_modal"
    >
      <div>
        <!-- form -->
        <validation-observer
          ref="credit_card_pay_invoice"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form"
            @submit.prevent="btn_credit_card_pay_invoice"
          >
            <!-- cardno -->
            <b-row class="justify-content-center">
              <b-col md="10">
                <b-form-group>
                  <label for="card_no"><span class="text-danger text-bold">*</span> {{ $t('creditcardpay.card_no') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="card_no"
                    rules="required"
                  >
                    <cleave
                      id="card_no"
                      v-model="card_no"
                      class="form-control"
                      :raw="false"
                      :options="options.creditCard"
                      :placeholder="$t('creditcardpay.card_no')"
                      :state="errors.length > 0 ? false: card_no != null ? true : null"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- cardno -->

            <!-- exp_date and cvv -->
            <b-row class="justify-content-center">
              <b-col md="5">
                <b-form-group>
                  <label for="exp_date"><span class="text-danger text-bold">*</span> {{ $t('creditcardpay.exp_date') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="exp_date"
                    rules="required"
                  >
                    <cleave
                      id="exp_date"
                      v-model="exp_date"
                      class="form-control"
                      :state="errors.length > 0 ? false: exp_date != null ? true : null"
                      :raw="false"
                      :options="options.exp_date"
                      :placeholder="$t('creditcardpay.exp_date_placeholder')"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group>
                  <label for="cvv"><span class="text-danger text-bold">*</span>CVV:</label>
                  <validation-provider
                    #default="{ errors }"
                    name="cvv"
                    rules="required"
                  >
                    <cleave
                      id="cvv"
                      v-model="cvv"
                      class="form-control"
                      :state="errors.length > 0 ? false: cvv != null ? true : null"
                      :raw="false"
                      :options="options.cvv"
                      placeholder="CVV"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- exp_date and cvv -->

            <!-- full_name -->
            <b-row class="justify-content-center">
              <b-col md="10">
                <b-form-group>
                  <label for="card_holder"><span class="text-danger text-bold">*</span> {{ $t('creditcardpay.card_holder') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="card_holder"
                    rules="required"
                  >
                    <b-form-input
                      id="card_holder"
                      v-model="card_holder"
                      :state="errors.length > 0 ? false: card_holder != null ? true : null"
                      name="card_holder"
                      :placeholder="$t('creditcardpay.card_holder')"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- full_name -->
            <!-- checkbox -->
            <b-row class="  justify-content-center pt-1 mb-1 bg-light-warning mx-auto">
              <b-col md="10">
                <validation-provider
                  #default="{ errors }"
                  name="checkbox"
                  rules="required"
                >
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="chekcbox_status"
                    :value="true"
                    :unchecked-value="null"
                    switch
                    :state="chekcbox_status != null ? true : null"
                    name="checkbox-1"
                  >
                    'geribildir.io', {{ $t('payment_method.creditcardpay_accept') }}
                  </b-form-checkbox>
                  <small
                    v-if="chekcbox_status == false"
                    class="text-danger"
                  >{{ $t('core.required') }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- checkbox -->
            <b-row class="justify-content-end mx-1 mb-1">
              <b-button
                size="sm"
                variant="primary"
                class="mr-1"
                type="sumbit"
                :disabled="invalid"
              >
                <span class="align-middle">Şimdi Öde</span>
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>

      </div>

    </b-modal>
    <!--    Kredi Kartı Modal-->

    <b-card no-body>
      <b-card-header class="p-0 justify-content-center bg-warning align-items-center">
        <h4 class="text-white pt-50">
          {{ $t('invoice_management.billing_information') }}
        </h4>
      </b-card-header>
      <b-row class="mx-50 mt-1">
        <b-alert
          v-if="subscription_data"
          variant="primary"
          class="w-100"
          show
        >
          <div class="alert-body">
            <span v-if="subscription_data.company.plan_period==='monthly'">You currently receive 1 invoice a month.<br></span>
            <span v-if="subscription_data.company.plan_period==='annually'">You currently receive 1 invoice a annual.<br></span>
            <span>The invoice will be created on the following day:
              <b-badge variant="secondary">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-25"
                />{{ subscription_data.next_billing_date }}
              </b-badge>
            </span>
          </div>
        </b-alert>
      </b-row>

      <b-row class="mt-50 mx-0">
        <b-table
          responsive
          :items="table_data"
          :fields="tableColumns"
          primary-key="id"
          class="text-nowrap text-center p-0 m-0"
          style="min-height: 300px"
          show-empty
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          :empty-text="$t('core.no_records_found')"
          small
          borderless
        >
          <!-- Column: id -->
          <template #cell(id)="data">
            <div
              v-if="data.item.invoice_status=='paid'"
            >
              <b-badge
                variant="success"
              >
                <feather-icon icon="CheckCircleIcon" /> Ödendi
              </b-badge>
            </div>
            <div
              v-else-if="data.item.invoice_status=='unpaid'"
            >
              <b-badge
                variant="danger"
                @click="rowCreditCardModal(data.item.id)"
              >
                <feather-icon icon="CreditCardIcon" /> Hemen Öde
              </b-badge>
              <b-badge
                class="ml-25"
                variant="danger"
              >
                <feather-icon
                  class="mr-25"
                  icon="AlertTriangleIcon"
                />{{data.item.warning_level}}
              </b-badge>
            </div>
          </template>

          <!-- Column: download -->
          <template #cell(download)="data">
            <b-badge
              variant="secondary"
            >
              <feather-icon icon="DownloadIcon" /> PDF
            </b-badge>
          </template>

          <!-- Column: amount -->
          <template #cell(amount)="data">
            <span v-if="data.item.order_currency&&data.item.order_currency.is_before==true">{{ data.item.order_currency.currency_symbol }} {{ data.item.total_amount }}</span>
            <span v-if="data.item.order_currency&&data.item.order_currency.is_before==false"> {{ data.item.total_amount }} {{ data.item.order_currency.currency_symbol }}</span>
          </template>
        </b-table>
      </b-row>
      <div class="card-footer">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BLink,
  BModal,
  BPagination,
  BRow,
  BTable,
  VBModal,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import { required } from '@core/utils/validations/validations'

export default {
  components: {
    BFormCheckbox,
    BForm,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    BModal,
    BCardText,
    BTable,
    BPagination,
    BDropdownItem,
    vSelect,
    BDropdown,
    BBadge,
    BAlert,
    BLink,
    BCardHeader,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
  },
  data() {
    return {
      form: {
        cardNumber: null,
        date: null,
        phone: null,
      },
      options: {
        creditCard: {
          delimiter: '-',
          blocks: [4, 4, 4, 4],
        },
        cvv: {
          blocks: [3],
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        exp_date: {
          date: true,
          delimiter: '/',
          datePattern: ['m', 'y'],
          blocks: [2, 2],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+63',
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      },
      iframe: {
        src: null,
        loaded: false,
      },
      temp_order_no: null,
      payment_modal_tab: 'iframe',
      payment_error_message: null,
      card_no: null,
      exp_date: null,
      cvv: null,
      card_holder: null,
      chekcbox_status: null,
      required,
      selected_row_name: null,
      selected_row_id: null,
      perPageOptions: [25, 50, 100],
      searchQuery: null,
      sortBy: null,
      isSortDirDesc: null,
      table_data: null,
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      user_data: null,
      tableColumns: [
        { key: 'invoice_no', label: 'Inv-No', sortable: true },
        { key: 'due_date', label: 'Date', sortable: true },
        { key: 'download', label: 'Download', sortable: true },
        { key: 'amount', label: 'Amount', sortable: true },
        { key: 'id', label: '#' },
      ],
      subscription_data: null,
    }
  },
  watch: {
  },
  created() {
    this.user_data = getUserData()
    this.get_subscription_data()
    this.get_invoice_data()
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
  },
  methods: {
    receiveMessage(event) {
      if (event.data.func === 'payment_log') {
        if (event.data.message.payment_status === 'True') {
          this.payment_modal_tab = 'success'
          this.PaymentCheck()
        } else {
          this.payment_modal_tab = 'error'
          this.payment_error_message = event.data.message.message
        }
      }
    },
    load() { this.iframe.loaded = true },
    PaymentCheck() {
      const payload = {
        order_no: this.temp_order_no,
      }
      useJwt.paymentcheck(payload)
        .then(response => {
          if (response.data.order_status === 'processing') {
            setTimeout(this.PaymentCheck, 3000) // 3 saniye sonra tekrar çalışır
          } else if (response.data.order_status === 'completed') {
            this.closePaymentModal()
            this.close_credit_card_modal()
            this.get_invoice_data()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('core.record_updated'),
                icon: 'SaveIcon',
                variant: 'success',
              },
            })
          } else if (response.data.order_status === 'canceled') {
            console.log('kart eklenemedi hatası  verilecek')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('core.error'),
                icon: 'SaveIcon',
                variant: 'danger',
              },
            })
            this.payment_modal_tab = 'error'
            this.payment_error_message = `${response.data.failed_reason_code} - ${response.data.failed_reason_msg}`
          }
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    btn_credit_card_pay_invoice() {
      this.$refs.credit_card_pay_invoice.validate().then(success => {
        if (success) {
          const payload = {
            card_no: this.card_no.replaceAll('-', ''),
            exp_date: this.exp_date,
            cvv: this.cvv,
            invoice_id: this.selected_row_id,
            card_holder: this.card_holder,
          }
          useJwt.invoicepaywithcreditcard(payload)
            .then(response => {
              if (response.data.status === true) {
                this.$bvModal.show('modal-1')
                this.iframe.src = response.data.threeDSHtmlContent
                this.temp_order_no = response.data.order_no
              } else {
                this.$bvModal.hide('modal-1')
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('core.error'),
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  variant: 'danger',
                  text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
                },
              })
            })
        }
      })
    },
    closePaymentModal() {
      this.$bvModal.hide('modal-1')
      this.iframe = { src: null, loaded: false }
      this.payment_modal_tab = 'iframe'
    },
    get_subscription_data() {
      useJwt.getSubscriptionList({})
        .then(response => {
          if (response.data.results.length) {
            this.subscription_data = response.data.results[0]
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    get_invoice_data() {
      useJwt.getInvoiceList({})
        .then(response => {
          this.table_data = response.data.results
        })
        .catch(error => {
          console.log(error)
        })
    },
    rowCreditCardModal(row_id) {
      this.selected_row_id = row_id
      this.$refs.credit_card_modal.show()
    },
    close_credit_card_modal() {
      this.$refs.credit_card_modal.hide()
      this.selected_row_id = null
    },
  },
}
</script>
<style>
</style>
