var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0 justify-content-center bg-warning align-items-center"},[_c('h4',{staticClass:"text-white pt-50"},[_vm._v(" "+_vm._s(_vm.$t('company_detail.company_information'))+" ")])]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.btn_update_return_url_data($event)}}},[_c('b-row',{staticClass:"mx-1"},[_c('b-col',[_c('b-form-group',[_c('label',{attrs:{"for":"country"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('company_detail.country')))]),_c('b-form-input',{attrs:{"id":"country","disabled":"","placeholder":_vm.$t('company_detail.country')},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1)],1)],1),_c('b-row',{staticClass:"mx-1"},[_c('b-col',[_c('b-form-group',[_c('label',{attrs:{"for":"company_name"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('company_detail.company_name')))]),_c('validation-provider',{attrs:{"name":"company_name","vid":"company_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company_name","state":errors.length > 0 ? false: _vm.company_name != null ? null : null,"placeholder":_vm.$t('company_detail.company_name')},model:{value:(_vm.company_name),callback:function ($$v) {_vm.company_name=$$v},expression:"company_name"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"mx-1"},[_c('b-col',[_c('b-form-group',[_c('label',{attrs:{"for":"tax_no"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('company_detail.tax_no')))]),_c('validation-provider',{attrs:{"name":"tax_no","vid":"tax_no","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tax_no","state":errors.length > 0 ? false: _vm.tax_no != null ? null : null,"placeholder":_vm.$t('company_detail.tax_no')},model:{value:(_vm.tax_no),callback:function ($$v) {_vm.tax_no=$$v},expression:"tax_no"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"mx-1"},[_c('b-col',[_c('b-form-group',[_c('label',{attrs:{"for":"postal_code"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('company_detail.postal_code')))]),_c('validation-provider',{attrs:{"name":"postal_code","vid":"postal_code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"postal_code","state":errors.length > 0 ? false: _vm.postal_code != null ? null : null,"placeholder":_vm.$t('company_detail.postal_code')},model:{value:(_vm.postal_code),callback:function ($$v) {_vm.postal_code=$$v},expression:"postal_code"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"mx-1"},[_c('b-col',[_c('b-form-group',[_c('label',{attrs:{"for":"city"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('company_detail.city')))]),_c('validation-provider',{attrs:{"name":"city","vid":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","state":errors.length > 0 ? false: _vm.city != null ? null : null,"placeholder":_vm.$t('company_detail.city')},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"mx-1"},[_c('b-col',[_c('b-form-group',[_c('label',{attrs:{"for":"phone_no"}},[_c('span',{staticClass:"text-danger text-bold"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('company_detail.phone_no')))]),_c('validation-provider',{attrs:{"name":"phone_no","vid":"phone_no","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone_no","state":errors.length > 0 ? false: _vm.phone_no != null ? null : null,"placeholder":"+905433343322"},model:{value:(_vm.phone_no),callback:function ($$v) {_vm.phone_no=$$v},expression:"phone_no"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('core.required')))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"justify-content-end mx-1 mb-1"},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"primary","type":"sumbit","disabled":invalid}},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('core.btn_update')))])])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }