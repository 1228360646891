<template>
  <div>
    <b-row class="justify-content-center pt-2">
      <b-col cols="2">
        <b-card no-body>
          <b-card-header class="p-0 justify-content-center bg-primary align-items-center">
            <h4 class="text-white pt-50">
              {{ $t('account_management.account_management') }}
            </h4>
          </b-card-header>
          <b-card-body>
            <b-nav class="mt-1" vertical>
              <b-nav-item disabled>{{ $t('account_management.profile') }}</b-nav-item>
              <div class="ml-1">
                <b-nav-item :class="this.$route.path=='/company_detail' ? 'custom-class':''" href="/company_detail">{{ $t('company_detail.company_information') }}</b-nav-item>
              </div>

              <b-nav-item disabled>{{ $t('invoice_management.invoices') }}
              </b-nav-item>
              <div class="ml-1">
                <b-nav-item :class="this.$route.path=='/invoices_detail' ? 'custom-class':''" href="/invoices_detail">{{ $t('invoice_management.invoices') }}</b-nav-item>
                <b-nav-item :class="this.$route.path=='/plan_management' ? 'custom-class':''" href="/plan_management">{{ $t('plan_management.plan_management') }}</b-nav-item>
                <b-nav-item :class="this.$route.path=='/payment_method' ? 'custom-class':''" href="/payment_method">{{ $t('payment_method.payment_method') }}</b-nav-item>
              </div>

<!--              <b-nav-item disabled>Ayarlar-->
<!--              </b-nav-item>-->
<!--              <div class="ml-1">-->
<!--                <b-nav-item-->
<!--                    v-if="this.$ability.can('read', 'return_url_management')"-->
<!--                    :class="this.$route.path=='/return_url_management' ? 'custom-class':''" href="/return_url_management">Genel Tanımlar</b-nav-item>-->
<!--                <b-nav-item :class="this.$route.path=='/password_change' ? 'custom-class':''" href="/password_change">Parola</b-nav-item>-->
<!--              </div>-->
            </b-nav>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="6">
        <div v-if="this.$route.path=='/return_url_management'"><general-definitions /></div>
        <div v-if="this.$route.path=='/payment_method'"><payment-method /></div>
        <div v-if="this.$route.path=='/password_change'"><password /></div>
        <div v-if="this.$route.path=='/company_detail'"><company-detail /></div>
        <div v-if="this.$route.path=='/invoices_detail'"><invoices /></div>
        <div v-if="this.$route.path=='/plan_management'"><plan-management /></div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BCardHeader, BCardText, BCardTitle, BCol, BDropdownDivider, BNav, BNavItem, BRow,
  VBModal,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import GeneralDefinitions
  from "@/views/custom_app/client/account_management/general_definitions/GeneralDefinitions.vue"
import PaymentMethod from "@/views/custom_app/client/account_management/payment_method/PaymentMethod.vue";
import Password from "@/views/custom_app/client/account_management/password/Password.vue";
import CompanyDetail from "@/views/custom_app/client/account_management/company_detail/CompanyDetail.vue";
import Invoices from "@/views/custom_app/client/account_management/invoices/Invoices.vue";
import PlanManagement from "@/views/custom_app/client/account_management/plan_management/PlanManagement.vue";
export default {
  components: {
    BDropdownDivider,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCard,
    BRow,
    BCol,
    BNav,
    BNavItem,
    GeneralDefinitions,
    PaymentMethod,
    Password,
    CompanyDetail,
    Invoices,
    PlanManagement,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
  },
  data() {
    return {
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {},
  methods: {
    getCustomClass(nav_tag){
      console.log()

      return "custom-class"

    }
  },
}
</script>
<style>
/* Özel CSS Sınıfı İçerisindeki <a> Etiketlerinin Rengini Değiştirme */
.custom-class a {
  color: #e67e23 ; /* Burada istediğiniz rengi belirleyin */
}
</style>